import React, { useState } from 'react';
import axios from 'axios';
import Logo from '../../assets/images/lndexlogo_svg.svg';
import { useNavigate } from 'react-router-dom';
import { URL } from '../../config';
const ChangePassword = () => {
    const [password, setPassword] = useState('');
    const [Code, setCode] = useState('');
    const [error, setError] = useState('');
  const navigate = useNavigate();
    const handleSubmit = async () => {
        try {
          const response = await axios.post(`${URL}/change-password`, {
            forget_code : Code,
            new_password : password
          });
          console.log('Password reset link sent:', response.data);
          navigate('/Login')
          // Handle successful response here
        } catch (err) {
          setError('This Code does not Valid.');
          console.error('Error:', err);
        }
      };
       // handle CanCel   
        const handleCancel = () => {
            navigate('/Login')
        }
  return (
    <div>
          <div className='Registrition_Body'>
      {/* Start signup body */}
      <div className='Signup_body'>
        <div className='Signup_body_Form'>
          <div className='Signup_body_Form_Sec1'>
            <img src={Logo} alt='Logo' />
          </div>
          <div className='Signup_body_Form_Sec2'>
            ChangePassword
          </div>
          
          {/* Start input Field */}
          <div className='Signup_body_input_container'>
            {/* Email */}
            <div className='Signup_body_Form_Sec4'>
              {/* <div>Email</div> */}
              <div style={{display:'flex',justifyContent:'center'}} className='Signup_body_input'>
                <input
                  style={{padding:'10px',}}
                  className='Sign_input'
                  type='text'
                  placeholder='Enter Code'
                  value={Code}
                  onChange={(e) => setCode(e.target.value)}
                />
          
              </div>

                  {/* Section About Password  */}
              <div style={{display:'flex',justifyContent:'center'}} className='Signup_body_input'>
                <input
                  style={{padding:'10px',}}
                  className='Sign_input'
                  type='password'
                  placeholder='Enter New password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
          
              </div>
            </div>
          </div>
          {/* End input Field Container */}

          {error && <div className='Error_message'>{error}</div>}
          <div className='Registration_body_Form_Sec5'>
            <button className='Signup_body_Form_Sec5_button' onClick={handleSubmit}>
              Continue
            </button>
            <button  style={{background:'gray'}} className='Signup_body_Form_Sec5_button' onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </div> {/* End Form */}
      </div>
    </div>
    </div>
  )
}

export default ChangePassword