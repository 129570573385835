import React , { useState } from 'react'
//import ArrowLeftGuide from '../../assets/images/ArrowLeft.svg'
//import Line from '../../assets/images/Line.svg'
//import { Link } from 'react-router-dom'
import './CartPage.css'
import ArrowLeftGuide from '../../assets/images/ArrowLeft.svg'
import { Link } from 'react-router-dom'
import Line from '../../assets/images/Line.svg'
import CourseImage from '../../assets/images/Course/cart_course_img.svg'
import ClockIcon from '../../assets/images/Course/cart_circle-clock.svg'
import ArabicIcon from '../../assets/images/Course/cart_volume.svg'
import DeleteIcon from '../../assets/images/Course/cart_Delete.svg'
import ExitIcon from '../../assets/images/Course/ExitIcon.svg'
import cartckeckout from '../../assets/images/Course/cart_shoping_checkout.svg'
import ArrowCheckout from '../../assets/images/Course/cart_arrow_checkout.svg'
import LineCheckout from '../../assets/images/Course/CheckoutLine.svg'
import DiscountIcon from '../../assets/images/Course/discount-percent-checkout.svg'
const CartPage = () => {
  const [showdelete , setShowdelete] = useState(false)
  const handlepopup = ()=> {
     setShowdelete(!showdelete)
  }
  return (
    <div className='Cart_Container'>     
     {/* 
      <div className='Blog_Sec1'>
            <Link to='/' className='link'><div>home</div> </Link>
                <div><img src={ArrowLeftGuide} alt='arrow'/></div> 
                <div>Cart</div>
            </div>
            */}
            {/* Start Design Patrition / Part  One */}
            <div className='Cart_Part1'>
                  {/* Part 1 Section one */}
                 <div className='Cart_Part1_sec1'> 
                    <div className='Cart_Part1_sec1_header'>
                         <div className='Cart_Part1_sec1_header_R'>Shopping cart ( 3 courses )</div>
                         <Link to='/'><div className='Cart_Part1_sec1_header_R2'>Continue shoping</div></Link>
                    </div>
                    {/* body  */}
                      <div className='Cart_Part1_sec1_body'>
                         <img className='cart_line' src={Line} alt='Line'/>
                         {/* Course At Cart */}
                         <div className='Cart_Part1_sec1_body_course'>
                                <div className='Cart_Part1_sec1_body_course_part1'>
                                  <img src={CourseImage} alt='CourseImage'/>
                                     <div className='Cart_Part1_sec1_body_course_part1_info'>
                                         <div className='Cart_Part1_sec1_body_course_part1_coursename'>Psychology of Conspiracy Theories</div>
                                         <div className='Cart_Part1_sec1_body_course_part1_instructorname'>Ahmed Zayed</div>
                                         <div className='Cart_Part1_sec1_body_course_part1_clock'><img src={ClockIcon} alt='ClockIcon'/>6 hours (11 sessions)</div>
                                         <div className='Cart_Part1_sec1_body_course_part1_clock'><img src={ArabicIcon} alt='ArabicIcon'/>Arabic</div>
                                     </div>
                                </div>
                                <div className='Cart_Part1_sec1_body_course_part2'>
                                  <div className='Cart_Part1_sec1_body_course_part2_price'>600 EGP</div>
                                  <img onClick={setShowdelete} src={DeleteIcon} alt='DeleteIcon'/>
                                </div>
                         </div>
                         <img className='cart_line' src={Line} alt='Line'/>
                         {/* the second course */}

                         <div className='Cart_Part1_sec1_body_course'>
                                <div className='Cart_Part1_sec1_body_course_part1'>
                                  <img src={CourseImage} alt='CourseImage'/>
                                     <div className='Cart_Part1_sec1_body_course_part1_info'>
                                         <div className='Cart_Part1_sec1_body_course_part1_coursename'>Psychology of Conspiracy Theories</div>
                                         <div className='Cart_Part1_sec1_body_course_part1_instructorname'>Ahmed Zayed</div>
                                         <div className='Cart_Part1_sec1_body_course_part1_clock'><img src={ClockIcon} alt='ClockIcon'/>6 hours (11 sessions)</div>
                                         <div className='Cart_Part1_sec1_body_course_part1_clock'><img src={ArabicIcon} alt='ArabicIcon'/>Arabic</div>
                                     </div>
                                </div>
                                <div></div>
                         </div>
                         
                      </div>
                  </div>
                       {/* Part 1 Section TWo (2) */}
                    
                       <div className='Cart_Part1_sec2'>
                          <div className='Cart_Part1_sec2_price'>
                             <div className='Cart_Part1_sec2_price_total'>total</div>
                             <div className='Cart_Part1_sec2_price_totalNum'>1200 EGP</div>
                          </div>
                          <div className='Cart_Part1_sec2_price'>
                             <div className='Cart_Part1_sec2_discount'>Discount 20%</div>
                             <div className='Cart_Part1_sec2_discount_totalNum'>-600 EGP</div>
                          </div>
                          <div className='Cart_Part1_sec2_checkout'>
                            <img src={cartckeckout} alt='cartckeckout'/> Checkout <img src={ArrowCheckout} alt='ArrowCheckout'/>
                          </div>
                             <div style={{display:'flex',justifyContent:'center',alignItems:'center',paddingLeft:'20px'}}>
                                <img src={LineCheckout} alt='LineCheckout'/>
                             </div>
                             <div className='Cart_Part1_sec2_discountcode'>
                                <img src={DiscountIcon} alt='DiscountIcon'/>Do you have discount code ?
                             </div>
                             <div className='Cart_Part1_sec2_discountInput'>
                                       <input className='Cart_Part1_sec2_discountInputfield' placeholder='Lndexfirst10'></input>
                                       <button className='Cart_Part1_sec2_discount_button'>APPLY</button>
                             </div>
                       </div>


            </div>
            {/* POP up delete  */}
            {showdelete && ( 
              <div className='Cart_Delete'>

                  <div className='Cart_Delete_Exit'> <div></div> <img onClick={handlepopup} src={ExitIcon} alt='ExitIcon'/> </div>
                  <div className='Cart_Delete_Question'>Are you sure you want to delete this Course?</div>
                  <div className='Cart_Delete_Answer'>The list will be deleted, along with all the courses you added to it.</div>
                    <div className='Cart_Delete_Button'>
                       <button onClick={handlepopup} className='Cart_Delete_Button_cancel'>Cancel</button>
                       <button onClick={handlepopup} className='Cart_Delete_Button_Delete'>Delete</button>
                    </div>
                </div>
            )}
            
    </div>
  )
}
export default CartPage
