import React , { useState } from 'react'
import './UnauthAppbar.css'
import Discount from '../../assets/images/appbardiscount.png'
import Logo from '../../assets/images/lndexlogo_svg.svg'
import Arrowup from '../../assets/images/Arrow_up.png'
import SearchIcon from '../../assets/images/search_icon.png'
import BlogIcon from '../../assets/images/blogSvg.svg'
import ArabicIcon from '../../assets/images/Arabicon.png'
import ShoppingCart from '../../assets/images/shoppingCart_ar.png'
import { Link } from 'react-router-dom'
import { useLanguage } from '../../context/LanguageContext'
const UnauthAppbarAR = ({ showSignup, setShowSignup ,showLogin , setShowLogin }) => {
   // this from context api to manage language 
      const { language, toggleLanguage } = useLanguage();
      console.log(language)   
  // search Function 
        const [searchText, setSearchText] = useState('');
  
        const handlesign =()=>{
          setShowSignup(true);
          console.log('test')
        }
        // Function to Handle Login Form
        const handleLogin =()=>{
          setShowLogin(true);
          console.log('test')
        } 
        const handleInputChange = (e) => {
          setSearchText(e.target.value);
        //  console.log(searchText)
        }; 
        const handleSearch = () => {
            // Add your search functionality here
            console.log("Searching for:", searchText);
          };
        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          };
          const handleIconClick = () => {
            handleSearch();
            handleKeyDown({ key: 'Enter' }); // Simulate Enter key press
          };
  return (
    <div className='UnauthAppbar_container'>
        <div className='UnauthAppbar_First_app_ar'>
           <div></div>
           <div className='UnauthAppbar_First_app_Text_ar'><img src={Discount} alt='discounticon'/>سجل الآن لتحصل على خصم 20% على الكورسات </div>
           {/* this button must navigate to signup page React-route-dom  */}
          {/*  <div className='UnauthAppbar_First_app_Button_container'><button className='UnauthAppbar_First_app_Button_ar' onClick={handlesign}>اشترك الان  </button></div>*/}
          <div></div>
        </div>
          {/* The Second Appbar  */}
      <div className='UnauthAppbar_Second_app_ar'>
             <div><img style={{display:'flex',alignItems:'center',justifyContent:'center',paddingTop:'10px'}} src={Logo} alt='lndexLogo'></img></div>
             <div className='UnauthAppbar_Second_app_Part2_ar'>
               <div className="search-container">
               <img src={SearchIcon} alt="search icon" className="search-icon-ar" onClick={handleIconClick}   />
                  <input 
                  className="Search_ar" 
                  type="text" 
                  placeholder="البحث ......"
                  value={searchText}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  onClick={handleIconClick}
                  />
             </div>
                <div><button className='UnauthAppbar_Second_app_button_ar'>جميع الاقسام <img src={Arrowup} alt='arrowicon'></img></button></div>
               <div className='UnauthAppbar_Second_app_Blog_ar'><img src={BlogIcon} alt='BlogIcon' />  <Link to='/blogs' className='link'>المقالات</Link></div>
             </div>
             <div className='UnauthAppbar_Second_app_Part3_ar'>
               <div className='UnauthAppbar_Second_app_Part3_Language_ar' onClick={toggleLanguage}><img src={ArabicIcon} alt='ArabicIcon'/> {language === 'en' ? 'En' : <div className='Language_Arabic'>ع</div>}</div>
               <div className='UnauthAppbar_Second_app_Part3_Login_ar'><button className='UnauthAppbar_Second_app_Part3_Login_ar' onClick={handleLogin}>تسجيل الدخول </button></div>
               <div className='UnauthAppbar_Second_app_Part3_Login_ar'><button className='UnauthAppbar_Second_app_Part3_Login_ar' onClick={handlesign}>اشترك الان  </button></div>
               <div className='UnauthAppbar_Second_app_Part3_Cart_ar'><img className='Cart_ar_icon' src={ShoppingCart} alt='ShoppingCart'/><div className='UnauthAppbar_Second_app_Part3_Cart_ar_number'>2</div></div>
             </div>
      </div>    
    </div>
  )
}
export default UnauthAppbarAR