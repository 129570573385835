import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import Alert from 'react-bootstrap/Alert';

function Error({Message}) {
  return (
    <Container>
      <Row>
        <Col xs={6} md={4}>
           <Alert variant='danger' style={{ padding: '5px 10px', margin: '15px 0px 0px 0px' ,width:'36vw' }}>
               <span style={{fontSize:'14px'}}>{Message}</span>
          </Alert>
        </Col>
      </Row>
    </Container>
  );
}

export default Error;