import React,{ useState , useRef  } from 'react'
import './HomeAR.css'
//import UnauthAppbar from '../../component/AppbarUnAuth/UnauthAppbar'
//import Signup from '../../component/Signup/Signup';
import Filmmiking from '../../assets/images/filming.png'
import aiicon from '../../assets/images/Ai.png'
import Business from '../../assets/images/Business.png'
import Fineart from '../../assets/images/FineArt.png'
import Design from '../../assets/images/design.png'
import Programming  from '../../assets/images/programming.png'
import sport from '../../assets/images/sport.png'
import cooking from '../../assets/images/cook.png'
import Wellness from '../../assets/images/wellness.png'
import animals from '../../assets/images/animal.png'
import Hash from '../../assets/images/hash.png'
import Course from '../../component/Course/Course';
import LeftArrow from '../../assets/images/leftarrow.png'
import RightArrow from '../../assets/images/rightarrow.png'
import StartIcon from '../../assets/images/StarReview.png'
import QouteIcon from '../../assets/images/quote.png'
//import ArrowUp from '../../assets/images/Scrollup.png'
//import LndexLogo from '../../assets/images/lndex logo.png'
//import Line from '../../assets/images/Line.svg'
//import FacebookIcon from '../../assets/images/facebookicon.svg'
//import YoutubeIcon from '../../assets/images/youtubeicon.svg'
//import InstgramIcon from '../../assets/images/instgramicon.svg'
import Instructor from '../../component/Instructor/Instructor';
import Article from '../../component/Article/Article';
//import Login from '../../component/Login/Login';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext'
//import FooterAR from '../../component/FooterArabic/FooterAr';
//import Footer from '../../component/Footer/Footer'
//import UnauthAppbarAR from '../../component/AppbarUnAuth/UnauthAppbarAR';
//import Signup from '../../component/Signup/Signup'
import HomePanner from '../../assets/images/lastpanner.svg'
const Home = () => {
    // this from context api to manage language 
    const { language } = useLanguage();
    console.log(language)
      /*
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
      */
      // to show Signup when click button 
    const [showSignup, setShowSignup] = useState(false);
      // to show Login when click button 
    const [showLogin, setShowLogin] = useState(false);
    const courseContainerRef = useRef(null);
    const scrollLeft = () => {
        if (courseContainerRef.current) {
            courseContainerRef.current.scrollLeft -= 200; // Adjust the value to scroll by
        }
    };
    const scrollRight = () => {
        if (courseContainerRef.current) {
            courseContainerRef.current.scrollLeft += 200; // Adjust the value to scroll by
            // about Scrollleft is buildin function at Dom APi
        }
    };
    const instructorContainerRef = useRef(null);
    const scrollInstructorLeft = () => {
        if (instructorContainerRef.current) {
            instructorContainerRef.current.scrollLeft -= 200; // Adjust the value to scroll by
        }
    };
    const scrollInstructorRight = () => {
        if (instructorContainerRef.current) {
            instructorContainerRef.current.scrollLeft += 200; // Adjust the value to scroll by
            // about Scrollleft is buildin function at Dom APi
        }
    };
    // about Article Refrence 
    const ArticleContainerRef = useRef(null);
    const scrollArticleLeft = () => {
        if (ArticleContainerRef.current) {
            ArticleContainerRef.current.scrollLeft -= 200; // Adjust the value to scroll by
        }
    };
    const scrollArticleRight = () => {
        if (ArticleContainerRef.current) {
            ArticleContainerRef.current.scrollLeft += 200; // Adjust the value to scroll by
            // about Scrollleft is buildin function at Dom APi
        }
    };
  return (
    <div>
        {/* 
         {language === 'en' ?
  <UnauthAppbar showSignup={showSignup} setShowSignup={setShowSignup} showLogin={showLogin} setShowLogin={setShowLogin}/>
            : 
  <UnauthAppbarAR showSignup={showSignup} setShowSignup={setShowSignup} showLogin={showLogin} setShowLogin={setShowLogin}/>
        }
      
        {showSignup ? <div className='Signup_home'><Signup setShowSignup={setShowSignup}/></div> : ''}
        {showLogin ?  <div className='Signup_home'><Login setShowLogin={setShowLogin}/> </div> : ''}
        */}
        <div className='Home_container_AR'>
         
            <div className='Home_Sec1'>
                <img src={HomePanner} alt='HomePanner' className='Home_Sec1_Panner'/>
                <div className='Home_Sec1_title_AR'>
                    <h2 className='Home_Sec1_title_h1'>"رحلتك التعليمية تبدأ هنا"</h2>
                    <p className='Home_Sec1_title_p'>استفادة غير محدودة لأكثر من 1,000 دورة تدريبية مصورة باللغة العربية، ابتداءً من 199.92 EGP</p>
                </div>
            </div>
         
        {/* Category hash SEction  */}
        <div className='Home_Sec2_AR'>
           <div className='Home_Sec2_part1_AR'>اكتشف دورات ملهمه اون لاين</div>
           <div className='Home_Sec2_part2_AR'>
              <div className='Home_Sec2_part2_items_AR'><img className='Home_Sec2_part2_items_Icon_AR' src={Filmmiking} alt='Filmmiking'/>صناعة الأفلام والتصوير الفوتوغرافي</div>
              <div className='Home_Sec2_part2_items_AR'><img className='Home_Sec2_part2_items_Icon_AR' src={aiicon} alt='aiicon'/>الذكاء الاصطناعي</div>
              <div className='Home_Sec2_part2_items_AR'><img className='Home_Sec2_part2_items_Icon_AR' src={Business} alt='Business'/>عمل</div>
              <div className='Home_Sec2_part2_items_AR'><img className='Home_Sec2_part2_items_Icon_AR' src={Fineart} alt='Fineart'/>الفنون الجميلة</div>
              <div className='Home_Sec2_part2_items_AR'><img  className='Home_Sec2_part2_items_Icon_AR' src={Design} alt='Design'/>تصميم</div>
              <div className='Home_Sec2_part2_items_AR'><img  className='Home_Sec2_part2_items_Icon_AR' src={Programming} alt='Programming'/>برمجة</div>
              <div className='Home_Sec2_part2_items_AR'><img className='Home_Sec2_part2_items_Icon_AR' src={sport} alt='sport'/>رياضات</div>
              <div className='Home_Sec2_part2_items_AR'><img className='Home_Sec2_part2_items_Icon_AR' src={cooking} alt='cooking'/>تصويب</div>
              <div className='Home_Sec2_part2_items_AR'><img className='Home_Sec2_part2_items_Icon_AR' src={Wellness} alt='Wellness'/>صحة</div>
              <br />  <br />  <br /> 
              <div className='Home_Sec2_part2_items_AR'><img src={animals} alt='animals'/>تربية الحيوان</div>
             
              <div className='Home_Sec2_part2_icon_AR'><img src={Hash} alt='Hash'/></div>
           </div>
        </div>
        {/*  The Course Header  */}
        <div className='Home_Sec3_AR'>
            <div className='Home_Sec3_Part1_AR'>دورات جديده</div>
            <div className='Home_Sec3_Part2_AR'>
            <img src={LeftArrow} alt='ArrowLeft' onClick={scrollLeft}  />
            <img src={RightArrow} alt='ArrowRight' onClick={scrollRight}  />
            </div>
        </div>
           {/*  The Course Body   */}
           <div className='Home_Sec4_AR' ref={courseContainerRef}>
           <Link to='coursedetails' className='link'>  <Course /></Link>
           <Link to='coursedetails' className='link'><Course /></Link> 
           <Link to='coursedetails' className='link'> <Course /></Link>
           <Link to='coursedetails' className='link'> <Course /></Link>
           <Link to='coursedetails' className='link'>  <Course /></Link>
           <Link to='coursedetails' className='link'> <Course /></Link>
           </div>
            {/* End The Course Body   */}
            <div className='Home_Sec3_AR'>
            <div className='Home_Sec3_Part1_AR'> تعرف علي خبراء ليندكس</div>
            <div className='Home_Sec3_Part2_AR'>
            <img src={LeftArrow} alt='ArrowLeft'   onClick={scrollInstructorLeft}/>
            <img src={RightArrow} alt='ArrowRight'  onClick={scrollInstructorRight} />
            </div>
        </div>
             {/*  The Instructor Body   */}
             <div className='Home_Sec5' ref={instructorContainerRef}>
            <Link to='/instructor' className='link'> <Instructor /></Link>
            <Link to='/instructor' className='link'> <Instructor /></Link>
            <Link to='/instructor' className='link'> <Instructor /></Link>
            <Link to='/instructor' className='link'> <Instructor /></Link>
            <Link to='/instructor' className='link'> <Instructor /></Link>
            <Link to='/instructor' className='link'> <Instructor /></Link>
            <Link>  <Instructor /></Link>
             </div>
               {/* End The Instructor Body   */}
               <div className='Home_Sec3_AR'>
               <div className='Home_Sec3_Part1_AR'>مدونات ومقالات  </div>
               <div className='Home_Sec3_Part2_AR'>
               <img src={LeftArrow} alt='ArrowLeft' onClick={scrollArticleLeft} />
               <img src={RightArrow} alt='ArrowRight' onClick={scrollArticleRight} />
               </div>
           </div>
             {/*  The Articles Body   */}
             <div className='Home_Sec6_AR' ref={ArticleContainerRef}>
             <Link to='/Article/1' className='link'>  <Article /></Link>    
                <Article />
                <Article />
                <Article /> 
             </div>
               {/* End The Articles Body   */}
               {/* strat section 7 about Student review  */}
               <div className='Home_Sec7_AR'>
                   <div className='Home_Sec7_Part1_AR'>لماذا يحب الطلاب ليندكس</div>
                   <div className='Home_Sec7_Part2_Container_AR'>
                       <div className='Home_Sec7_Part2_Container_Text_AR'>
                       "كنت مترددًا في الالتحاق بدورة تدريبية خارج الإنترنت في البداية، لكنني سعيد للغاية لأنني فعلت ذلك! كان المعلم واسع المعرفة ومتفاعلًا، كما أن صغر حجم الفصل جعل من السهل الحصول على المساعدة عندما كنت بحاجة إليها. لقد تعلمت الكثير في بالطبع، أود بالتأكيد أن أوصي بهذه المنصة لأي شخص يبحث عن دورة تدريبية عالية الجودة خارج الإنترنت."
                       </div>
                       <div className='Home_Sec7_Part2_Container_Star'>
                          <img src={StartIcon} alt='start'/>
                          <img src={StartIcon} alt='start'/>
                          <img src={StartIcon} alt='start'/>
                          <img src={StartIcon} alt='start'/>
                          <img src={StartIcon} alt='start'/>
                       </div>
                      <div className='Home_Sec7_Part2_Container_Student'>
                          <div className='Home_Sec7_Part2_Container_Student_Name_AR'>Muhammed Morsi</div>
                          <div className='Home_Sec7_Part2_Container_Student_Role_AR'>student</div>
                      </div>
                      <div className='QouteIcon'>
                      <img src={QouteIcon} alt='QouteIcon' />
                    </div>
                   </div>
                   {/* Second Review  */}
                   <div className='Home_Sec7_Part2_Container_AR'>
                   <div className='Home_Sec7_Part2_Container_Text_AR'>
                   "لقد أخذت دورة تدريبية حول تطوير الويب من موقع ليندكس. كان المدرب مطور ويب معتمدًا يتمتع بخبرة تزيد عن 10 سنوات. وكان واسع المعرفة وجذابًا للغاية، وجعل المادة سهلة الفهم. وكان حجم الفصل صغيرًا، لذلك لقد حصلت على الكثير من الاهتمام الفردي وأود بالتأكيد أن أوصي بهذه المنصة لأي شخص مهتم بتعلم تطوير الويب
                   </div>
                   <div className='Home_Sec7_Part2_Container_Star_AR'>
                      <img src={StartIcon} alt='start'/>
                      <img src={StartIcon} alt='start'/>
                      <img src={StartIcon} alt='start'/>
                      <img src={StartIcon} alt='start'/>
                      <img src={StartIcon} alt='start'/>
                   </div>
                  <div className='Home_Sec7_Part2_Container_Student_AR'>
                      <div className='Home_Sec7_Part2_Container_Student_Name_AR'>Ahmed hossam</div>
                      <div className='Home_Sec7_Part2_Container_Student_Role_AR'>student</div>
                  </div>
                  <div className='QouteIcon'>
                    <img src={QouteIcon} alt='QouteIcon' />
                  </div>
               </div>
               </div>
            
               {/* 
               <div className='Home_Sec8_Footer'>
                      <div className='Home_Sec8_Footer_Sec1' onClick={scrollToTop}>Back to top page <img src={ArrowUp} alt='ArrowUp' onClick={scrollToTop}/></div>
                       <div className='Line'><img src={Line} alt='Line'/></div>
                      <div className='Home_Sec8_Footer_Sec2'>
                           <img src={LndexLogo} alt='LndexLogo'/>
                      </div>
                      <div className='Home_Sec8_Footer_Sec3'>
                       <ul>
                          <li>About Lndex</li>
                          <li>Contact us</li>
                          <li>Help & Support</li>
                          <li>Register as a instructor</li>
                          <li>terms</li>
                          <li>Privacy policy</li>
                       </ul>
                      </div>
                      <div className='Home_Sec8_Footer_Sec4'>
                          <img src={FacebookIcon} alt='FacebookIcon'/>
                          <img src={YoutubeIcon} alt='youtubeIcon'/>
                          <img src={InstgramIcon} alt='insta'/>
                      </div>
                      <div className='Line'><img src={Line} alt='Line'/></div>
                      <div className='Home_Sec8_Footer_Sec5'>
                      @Copyright Lndex All rights Reserved
                      </div>
               </div>
              */}
        </div> {/* End Home Container */}
         {/* 
        <div className='Home_Sec8_Footer'>
               {language === 'en' ? <Footer /> : <FooterAR />}
               </div>
                 Start Sec 8 === Footer Section   */}
            
    </div>
  )
}
export default Home