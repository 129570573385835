import React from 'react'
import './InstructorReview.css'
import NumberStudent from '../../assets/images/instructors/numberStudent.svg'
import NumberArticle from '../../assets/images/instructors/numberArticle.svg'
import InstructorRate from '../../assets/images/instructors/StarIns.svg'
const InstructorReview = () => {
  return (
    <div className='InstructorReview_Container'>
          <div className='InstructorReview_Sec1'>
                <div className='InstructorReview_Sec1_Part1'>Reviews</div>
                <div className='InstructorReview_Sec1_Part2'>
                 <div className='InstructorReview_Sec1_Part2_item'><img src={NumberStudent} alt='NumberStudent'/> <div>400</div> <span>Students</span></div>
                 <div className='InstructorReview_Sec1_Part2_item'><img src={NumberArticle} alt='NumberArticle'/> <div>232</div> <span>Reviews</span></div>
                 <div className='InstructorReview_Sec1_Part2_item'><img src={InstructorRate} alt='InstructorRate'/> <div>95%</div> <span>Positive Rating
                 </span></div>
                </div>
          </div>
    </div>
  )
}
export default InstructorReview
