import React , {useState} from 'react'
import Signup from '../Signup/Signup';
const SignupBlog = () => {
    const [showSignup, setShowSignup] = useState(false);
    //const [showLogin, setShowLogin] = useState(false);
    const handlesign =()=>{
        setShowSignup(true);
        console.log('test')
      }
  return (
    <>
    <div className='Blog_Sec3'>
    <div className='Blog_Sec3_P1'>Sign up for your personalized newsletter.</div>
    <div className='Blog_Sec3_P2'><button className='UnauthAppbar_First_app_Button' onClick={handlesign}>Sign up </button></div>
  </div>
  {showSignup ? <div className='Signup_home'><Signup setShowSignup={setShowSignup}/></div> : ''} 
  </>
  )
}
export default SignupBlog