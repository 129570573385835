import React , { useState } from 'react'

const Description = () => {
  const [Expaned , setExpaned ] = useState(false)   
  const ToggleExpaned = () => {
      setExpaned(!Expaned)
  }
  return (
    <div className='Description_Container'>
        <div className='Description_Sec1'>
           <div className='Description_Sec1_header'>Description</div>
           <div className='Description_Sec1_header_body'>
           Quis hendrerit neque eget nibh. Nisl montes eu venenatis mauris imperdiet pellentesque neque ac ut. Eget faucibus imperdiet tincidunt viverra urna ultrices. Tincidunt urna enim adipiscing
        
           {Expaned && (
             <div>
                 augue sed. Metus sed neque accumsan accumsan mauris fermentum id odio. Enim amet vulputate scelerisque vel varius pellentesque vitae nec ornare. Non aliquam varius amet quisque. Leo ultrices enim tellus faucibus eget adipiscing ipsum eget rhoncus. Pellentesque cum porta ut sit ullamcorper sapien faucibus massa. Morbi vehicula in urna ultrices tincidunt interdum faucibus diam faucibus. Neque massa sit aenean nascetur nec sem arcu. Volutpat semper cum volutpat sodales id in. Faucibus id nisl vitae ridiculus donec. A quam tristique donec augue suscipit faucibus vel tempus. Nisl posuere integer mauris nibh massa mattis congue ornare dictum. Etiam nec pulvinar aliquam rhoncus ante gravida neque. Id aliquam consectetur commodo id nisi viverra est non orci. Leo commodo ligula consectetur natoque ut est. Erat mi sagittis praesent felis vel volutpat et. Nisl libero morbi at cursus fringilla nibh senectus. Rutrum purus facilisi morbi eget orci morbi. Eu ut turpis tincidunt tincidunt netus proin. Quis odio duis in leo eu sed at.
             </div>
           )}
            <div onClick={ToggleExpaned} className='Description_Sec1_Expaned'>{Expaned ? 'read less' : 'read More'}</div>
           </div>
          
        </div>
         {/* Start To Design Section 2  */}
         <div className='Description_Sec2'>
               <div className='Description_Sec2_header'>Upon completion of this course, students will be able to:</div>
               <div className='Description_Sec2_List'>
                 <ul>
                   <li>Define marketing and explain its key concepts and principles.</li>
                   <li>Identify the different types of marketing and their respective goals.</li>
                   <li>Understand the marketing process, from market research to customer relationship management.</li>
                   <li>Analyze target markets and develop marketing strategies to reach them.</li>
                   <li>Measure and evaluate the effectiveness of marketing campaigns.</li>
                 </ul>
               </div>
         </div>
    </div>
  )
}

export default Description