import React from 'react'
import './Course.css'
import CourseImg from '../../assets/images/Course/Courseimage.png'
import Clock from '../../assets/images/Course/circle-clock1.png'
import Chair from '../../assets/images/Course/Chair.png'
import Line from '../../assets/images/Course/Line.png'
import Start from '../../assets/images/Course/Star.png'
import Saved from '../../assets/images/Course/CourseSaved.png'
const Course = () => {
  return (
    <div className='Course_Container'>
         <div className='Course_Sec1'>
             <img className='Course_Sec1' src={CourseImg} alt='CourseImg' />
         </div>
             {/*  Sec2 Course Body  */}
        <div className='Course_Sec2'>
             Start 11 October
        </div>
        <div className='Course_Sec3'>
           Psychology of Conspiracy Theories
        </div>
        <div className='Course_Sec4'>
        <img src={Clock} alt='Clock'/> 6 hours &nbsp; (11 sessions)
     </div>
       <div className='Course_Sec5'>
         <img src={Chair} alt='Chair'/>30 seats left 
         <img src={Line} alt='Line'></img>
         <img src={Start} alt='Start'></img>95%(234)
      </div>
      <div className='Course_Sec6'>
      Ahmed Zayed
      </div>
      <div className='Course_Sec7'>
         <div></div>
         <img src={Saved} alt='Saved'></img>
      </div>

    </div>
  )
}
export default Course

