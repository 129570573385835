import React , { useState } from 'react'
import './UnauthAppbar.css'
import Discount from '../../assets/images/appbardiscount.png'
import Logo from '../../assets/images/lndexlogo_svg.svg'
import Arrowup from '../../assets/images/Arrow_up.png'
import SearchIcon from '../../assets/images/search_icon.png'
import BlogIcon from '../../assets/images/blogSvg.svg'
import ArabicIcon from '../../assets/images/worldLanguage.svg'
import ShoppingCart from '../../assets/images/shopping-cart.png'
import { Link } from 'react-router-dom'
import { useLanguage } from '../../context/LanguageContext'
const UnauthAppbar = ({ showSignup, setShowSignup ,showLogin , setShowLogin }) => {
        // this from context api to manage language 
        const { language, toggleLanguage } = useLanguage();
        console.log(language)
     // search Function 
        const [searchText, setSearchText] = useState('');
  
        const handlesign =()=>{
          setShowSignup(true);
          console.log('test')
        }
        // Function to Handle Login Form
        const handleLogin =()=>{
          setShowLogin(true);
          console.log('test')
        } 
        const handleInputChange = (e) => {
          setSearchText(e.target.value);
        //  console.log(searchText)
        }; 
        const handleSearch = () => {
            // Add your search functionality here
            console.log("Searching for:", searchText);
          };
        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          };
          const handleIconClick = () => {
            handleSearch();
            handleKeyDown({ key: 'Enter' }); // Simulate Enter key press
          };
  return (
    <div className='UnauthAppbar_container'>
        <div className='UnauthAppbar_First_app'>
           <div></div>
           <div className='UnauthAppbar_First_app_Text'><img src={Discount} alt='discounticon' />sign up now to Get 20%off on your first course</div>
           {/* this button must navigate to signup page React-route-dom  */}
           {/* <div className='UnauthAppbar_First_app_Button_container'><button className='UnauthAppbar_First_app_Button' onClick={handlesign}>Sign up </button></div> */}
           <div></div>
        </div>
          {/* The Second Appbar  */}
      <div className='UnauthAppbar_Second_app'>
             <Link to='/'><div><img style={{display:'flex',alignItems:'center',justifyContent:'center',paddingTop:'10px'}} src={Logo} alt='lndexLogo'></img></div></Link>
             <div className='UnauthAppbar_Second_app_Part2'>
               <div className="search-container">
                  <input 
                  className="Search" 
                  type="text" 
                  placeholder="Search..."
                  value={searchText}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  onClick={handleIconClick}
                  />
                  <img src={SearchIcon} alt="search icon" className="search-icon" onClick={handleIconClick}   />
             </div>
                <div><button className='UnauthAppbar_Second_app_button'>Categories <img src={Arrowup} alt='arrowicon'></img></button></div>
               <div className='UnauthAppbar_Second_app_Blog'><Link to='/blogs'><img src={BlogIcon} alt='BlogIcon' /></Link> <Link to='/blogs' className='link'> Blog</Link></div>
             </div>
             <div className='UnauthAppbar_Second_app_Part3'>
               <div className='UnauthAppbar_Second_app_Part3_Language' onClick={toggleLanguage}><img src={ArabicIcon} alt='ArabicIcon'/>{language === 'en' ? 'EN' :<div className='Language_Arabic'>ع</div>}</div>
               <div className='UnauthAppbar_Second_app_Part3_Login'><Link to='/Login' className='link'><button className='UnauthAppbar_Second_app_Part3_Login'>Login</button></Link></div>
               <div className='UnauthAppbar_Second_app_Part3_Login'><Link to='/Signup' className='link'> <button className='UnauthAppbar_Second_app_Part3_Login'>Sign up </button></Link></div>
                 {/*  
                      {/*  this comment code About The POPUP Login and SignUP   
               <div className='UnauthAppbar_Second_app_Part3_Login'><button className='UnauthAppbar_Second_app_Part3_Login' onClick={handleLogin}>Login</button></div>
               <div className='UnauthAppbar_Second_app_Part3_Login'><button className='UnauthAppbar_Second_app_Part3_Login' onClick={handlesign}>Sign up </button></div>
                 */}
               <div className='UnauthAppbar_Second_app_Part3_Cart'><img src={ShoppingCart} alt='ShoppingCart'/>Cart</div>
             </div>
      </div>    
    </div>
  )
}
export default UnauthAppbar
