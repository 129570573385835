import React ,{useState} from 'react';
import './Signup.css';
import SignupImage from '../../assets/images/signup.png';
import ExitIcon from '../../assets/images/Exit.png'
import Logo from '../../assets/images/lndexlogo_svg.svg'
const Signup = ({setShowSignup}) => {
     
    // exit sign form 
    const Exitsignup = ()=>{
            setShowSignup(false);
            console.log('test')
    }
           // username
    const [username, setUsername] = useState('');
    const handleInputChange = (e) => {
        setUsername(e.target.value);
    };
          // Email
    const [email, setEmail] = useState('');
    const handleEmail = (e) => {
        setEmail(e.target.value);
          };

            // Password
    const [Password, setPassword] = useState('');
    const handlepassword = (e) => {
        setPassword(e.target.value);
          };
  return (
    <div className='Signup'>
       <div className='Signup_header'>
         <div>Student</div>
         <div>Instructor</div>
       </div>
       {/* Start signup body  */}
       <div className='Signup_body'>
           <div><img src={SignupImage} alt='SignupImage'/></div>
           <div className='Signup_body_Form'>
             <div className='Signup_body_Form_Sec1'>
              <img src={ExitIcon} alt='ExitIcon' onClick={Exitsignup}/>
              <img src={Logo} alt='Logo'/>
              </div>
              <div className='Signup_body_Form_Sec2'>
                 Sign Up
             </div>
             <div className='Signup_body_Form_Sec3'>
                 <hr />
             </div>
             {/* strat input Field  */}
             <div className='Signup_body_input_container'>
             <div className='Signup_body_Form_Sec4'>
                <div>User name</div>
                <div className='Signup_body_input'>
                <input 
                className='Sign_input'
                type="text" 
                value={username} 
                onChange={handleInputChange} 
            /></div>
            </div>
            {/* Email */}
            <div className='Signup_body_Form_Sec4'>
              <div>Email</div>
              <div className='Signup_body_input'>
            <input 
            className='Sign_input'
            type="email" 
            placeholder='ayamustafa@gmail.com'
            value={email} 
            onChange={handleEmail} 
            /></div>
        </div>
             {/* Password */}
             <div className='Signup_body_Form_Sec4'>
             <div>Password</div>
             <div className='Signup_body_input'>
           <input 
           className='Sign_input'
           type="password" 
           placeholder='Password'
           value={Password} 
           onChange={handlepassword} 
           /></div>
       </div>
           </div> 
            {/* End input Field Container */}
            <div className='Signup_body_Form_Sec5'>
               <button className='Signup_body_Form_Sec5_button'>Sign up</button>
               <div>Already have an account? <span>Log In</span></div>
            </div>
               <div className='Signup_body_Form_Sec3'>
                 <hr />
               </div>
               <div className='Signup_body_Form_Sec6_footer'>
               by clicking on sign up, you agree to Lndex <span>Termes and conditions </span>& <span>Privacy policy</span>and Agree to receive news and promotions
               </div>
           </div> {/* End Form  */}
       </div>
    </div>
  )
}

export default Signup
