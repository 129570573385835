import React from 'react'
import LndexLogo from '../../assets/images/lndexlogo_svg.svg'
import Line from '../../assets/images/Line.svg'
import FacebookIcon from '../../assets/images/facebookicon.svg'
import YoutubeIcon from '../../assets/images/youtubeicon.svg'
import ArrowUp from '../../assets/images/Scrollup.png'
import InstgramIcon from '../../assets/images/instgramicon.svg'
const Footer = () => {
  const scrollToTop = () => {
    console.log('Scroll to top triggered');
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <div>
          {/*  Start Sec 8 === Footer Section   */}
          <div className='Home_Sec8_Footer'>
          <div className='Home_Sec8_Footer_Sec1' onClick={scrollToTop}>Back to top page <img src={ArrowUp} alt='ArrowUp' /></div>
           <div className='Line'><img className='Home_Sec8_Footer_line' src={Line} alt='Line'/></div>
          <div className='Home_Sec8_Footer_Sec2'>
               <img  src={LndexLogo} alt='LndexLogo'/>
          </div>
          <div className='Home_Sec8_Footer_Sec3'>
           <ul>
              <li>About Lndex</li>
              <li>Contact us</li>
              <li>Help & Support</li>
              <li>Register as a instructor</li>
              <li>terms</li>
              <li>Privacy policy</li>
           </ul>
          </div>
          <div className='Home_Sec8_Footer_Sec4'>
              <img src={FacebookIcon} alt='FacebookIcon'/>
              <img src={YoutubeIcon} alt='youtubeIcon'/>
              <img src={InstgramIcon} alt='insta'/>
          </div>
          <div ><img className='Home_Sec8_Footer_line' src={Line} alt='Line'/></div>
          <div className='Home_Sec8_Footer_Sec5'>
          @Copyright Lndex All rights Reserved
          </div>
   </div>
    </div>
  )
}
export default Footer
