
import React , { useState , useEffect } from 'react'
import UnauthAppbar from '../../component/AppbarUnAuth/UnauthAppbar'
import UnauthappbarAR from '../../component/AppbarUnAuth/UnauthAppbarAR'
import Signup from '../../component/Signup/Signup';
import Login from '../../component/Login/Login';
import Footer from '../../component/Footer/Footer';
import FooterAR from '../../component/FooterArabic/FooterAr';
import { useLanguage } from '../../context/LanguageContext'
import SignupPage from './SignupPage';
import MobileNavbar from '../../component/mobileNavbar/MobileNavbar';
const IndexLogin = () => {
    const [showSignup, setShowSignup] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
     // this from context api to manage language 
     const { language, toggleLanguage } = useLanguage();
     console.log('search page',language)
// const [language , toggleLanguage ]= useLanguage() ; .
// IsMobile and Responsive
const [isMobile, setIsMobile] = useState(false);
useEffect(() => {
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1200);
  };
  // Initial check
  handleResize();
  // Event listener for window resize
  window.addEventListener('resize', handleResize);
  // Clean-up
  return () => window.removeEventListener('resize', handleResize);
}, []);

  return (
    <div>
    
       {!isMobile && (<>
        {language === 'en' && isMobile === false ?  <UnauthAppbar showSignup={showSignup} setShowSignup={setShowSignup}
    showLogin={showLogin}
    setShowLogin={setShowLogin}
     /> 
          : 
          <UnauthappbarAR showSignup={showSignup} setShowSignup={setShowSignup}
    showLogin={showLogin}
    setShowLogin={setShowLogin}
    /> 
  } 
       </>)
       }
   {isMobile && (
       <MobileNavbar /> 
   )}
  
    {/* Use showSignup and setShowSignup as needed */}
   {language === 'en' ? <SignupPage />  : '<LoginPage />'} 
      {language === 'en' ?  <Footer/> : <FooterAR /> } 
    </div>

  )
}
export default IndexLogin