import React from 'react'
import './ArticleById.css'
import Articler from '../../assets/images/articler.png'
import ArtImage from '../../assets/images/ArtImage.svg'
const ArticleById = () => {
  return (
    <div>
    <div className='ArticleById_Container'> 
        <div className='ArticleById_Sec1'>
           <div className='ArticleById_Header'>Nature: The Beauty and Importance of the World Around Us</div>
        </div>
        {/* Style Card */}
        <div className='ArticleById_Sec2'>
             <div><img src={Articler} alt='Articler'/></div>
             {/* Another Title and name */}
             <div>
                 <div className='ArticleById_Sec2_Name'>Zeinab Sayed</div>
                 <div className='ArticleById_Sec2_Date'>17 October 2023</div>
             </div>
        </div>
            {/* Style Section Three  */}
            <div className='ArticleById_Sec3'><img src={ArtImage} alt='ArtImage'/></div>
                {/* Style Section Four  */}
                <div className='ArticleById_Sec4'>
                    <div className='ArticleById_Sec4_Title'>
                         Introduction
                     </div>
                     <div className='ArticleById_Sec4_Paraghraph'>
                     Nature is the world around us, including all living things and the physical environment. It is the sum of all the natural phenomena that occur on Earth, including the weather, climate, and the geological processes that shape our planet. Nature is essential to human life, providing us with food, water, air, and the resources we need to survive and thrive.
                     Nature is also incredibly beautiful and diverse. From the towering mountains and lush rainforests to the vast oceans and arid deserts, there is something to marvel at in every corner of the globe. Nature is also home to an astonishing variety of life, from the smallest insects to the largest whales.
                     In addition to its beauty and diversity, nature is also incredibly important to our physical and mental health. Spending time in nature has been shown to reduce stress, improve mood, and boost cognitive function. Nature also provides us with opportunities for recreation and exercise, which are essential for a healthy lifestyle.
                     Unfortunately, nature is facing many challenges today. Climate change, pollution, and habitat loss are all threatening the health and well-being of our natural world. It is more important than ever to protect nature and to educate others about its importance.
                     </div>
              </div>
   {/* close Container */} </div> 
          {/* Out Container  */}
     
   </div>
  )
}
export default ArticleById