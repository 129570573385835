import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div>
        Privacy Policy

This privacy policy was last updated on the 20th of February 2022.

All capitalized terms used and not otherwise defined herein shall have the meaning ascribed to them in almentor.net Terms of Use.

Any version of this Privacy Policy in any language other than English is provided for convenience, and you must understand and agree that the English language should prevail, in the event of any conflict.

You are contracting with ALMENTOR FZCO, a company located in Dubai Silicon Oasis, Dubai, United Arab of Emirates. ALMENTOR FZCO respects your rights regarding your privacy and personal information, and we provide this Privacy Policy to help you understand how we collect, use, share, store, and disclose the information we obtain from or about you when you use our services.  

This Privacy Policy applies to your use of these services. As used herein the term "use" shall mean use, access, install, sign into, connect with, download, visit, or browse the services. Furthermore, any information related to taking courses and/or watching talks included without limitation, interchanges with the courses/talks’ instructors, other students, answers, essays, and other items submitted to satisfy the course requirements, are referred to as "course information."

ALMENTOR FZCO allows you to enroll in and benefit from the online courses on a variety of topics that are taught by instructors, where, among other things, you will see reviews for courses by other students or viewers of the talks.

This privacy policy also describes the choices available to you regarding the use of, your access to, and how to update and correct your personal information.

This is a legally binding Agreement between you and ALMENTOR FZCO. While using the service, you fully agree to the terms of this Agreement. You should review this Privacy Policy in conjunction with our Terms and Conditions of use and any other agreement or policy provided by us that governs your use of these services. By providing us with your data, you warrant to us that you are over 18 years of age.

By using this Site, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.

Table of Contents:

The Scope of This Privacy Policy Our Policy

Concerning Minors

The Types of Information We May Collect

Payment Information

Technical & Usage Information We Collect Through Automated Means

Our Use of Cookies, Web Beacons, and Automated Information Collection

How We Use the Information We Collect

How Do We Keep Your Information Secure?

Your Choices Regarding the Use of Your Information How to Access & Update Your Information

Modifications to this Privacy Policy Questions
    </div>
  )
}
export default PrivacyPolicy