import React  from 'react'
import Line from '../../assets/images/Line.svg'
import UserImage from '../../assets/images/instructors/instructor1.png'
import { Link, useNavigate } from 'react-router-dom'
import './userProfile.css'
import Cookies from 'js-cookie';
import axios from 'axios'
import { URL } from '../../config';

const UserProfile = () => {
  
  const storedData = JSON.parse(localStorage.getItem("user_data"));
  console.log(storedData)
   const navigate = useNavigate(); //  define navigate from react router dom
      // logout function will trnasfer next at redux-toolkit 
   const logout = async () => {
   const token = Cookies.get('authToken'); // test token 
  
    try {
     const response =  await axios.get(`${URL}/logout`, {
        headers: {
          token: `${token}`,
        },
      });
  
      // If logout is successful, remove the token and redirect to the login page
      Cookies.remove('authToken');
      console.log("logout sucuss",response) 
      navigate('/a')
    } catch (error) {
      console.error('Logout failed:', error);
      // Optionally handle the error, such as showing a message to the user
    }
  };



  return (
    <div>
         {/* Start Instrector Page Container  */}
         <div className='UserPage_Continer'>
             {/* Start Instrector Page Container  */}
             <div className='UserPage_Header'>
                <Link to='/a' className='link'><h1>Lndex</h1></Link>
                 <button className='Logout_button' onClick={logout}>Logout</button>
             </div>
           {/* Start Design Section One  */}
           <div className='UserPage_Sec1'>
              <div className='UserPage_Sec1_Part1'></div>
               {/* Part 2  */}
                      {/* Part 2 = >  contain Section 2 and Section 3 inside section 1 
                       and have style space between around it 
                      */}
              <div className='UserPage_Sec1_Part2'>
                      {/* The Information User Section   */}
                      {/*  User Card Section 2    */}
                 <div className='UserPage_Sec2'>
                     <div className='UserPage_Sec2_Part1'>
                        <img className='UserImage' src={UserImage} alt='UserImage'></img>
                      </div>
                     <div className='UserPage_Sec2_part2'>
                             <div>
                                 <div className='UserPage_Sec2_part2_name'>
                                 {storedData.first_name +' '+storedData.last_name}
                                 </div>
                                 <div className='UserPage_Sec2_part2_Major'>Student</div>
                                <div style={{display:'flex',gap:'15px'}}> 
                                 <button className='Logout_button'>Upload Image</button>
                               <Link to='/update-profile'> <button className='Logout_button'>Update Profile</button></Link> 
                                 </div>
                             </div>
                            
                     </div>
                  </div>
                      {/*  END User Section 2  (Card) */}
                      {/* Section three User Relation Blog and Course in Profile  */}
                   <div className='UserPage_Sec3'>
                             <div className='UserPage_Sec3_Part1'>@LINKEDIN</div>
                             <div className='UserPage_Sec3_Part2'>
                                 <div className='UserPage_Sec3_Part2_item'><div>6</div><span>Saved Courses</span></div>
                                 <div className='UserPage_Sec3_Part2_item'><div>4</div><span>Saved Articles</span></div>
                              
                             </div>
                   </div>
                      {/*  END Section 3 */}
               
              </div>
                      {/*  Start Section 4 */} 
             <div className='Line'><img src={Line} alt='Line'/></div>
             <div className='UserPage_Sec5'>  
             
             </div>
           </div>
           
         </div>
    </div>
  )
}

export default UserProfile
