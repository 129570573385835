import React , { useState } from 'react'
import UnauthAppbar from '../../component/AppbarUnAuth/UnauthAppbar'
import Signup from '../../component/Signup/Signup';
import Login from '../../component/Login/Login';
import Footer from '../../component/Footer/Footer';
import CourseDetails from './CourseDetails';
const IndexInstructor = () => {
    const [showSignup, setShowSignup] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
  return (
    <div>
    <UnauthAppbar showSignup={showSignup} setShowSignup={setShowSignup}
    showLogin={showLogin}
    setShowLogin={setShowLogin}
    />
    {/* Use showSignup and setShowSignup as needed */}
    {showSignup ? <div className='Signup_home'><Signup setShowSignup={setShowSignup}/></div> : ''} 
    {showLogin ?  <div className='Signup_home'><Login setShowLogin={setShowLogin}/> </div> : ''}
    <CourseDetails />
       <Footer/>
    </div>
  )
}

export default IndexInstructor