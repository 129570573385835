
import React, { useState } from 'react';
import axios from 'axios';
import SignupImage from '../../assets/images/signup.png';
import Logo from '../../assets/images/lndexlogo_svg.svg';
import Cookies from 'js-cookie';
import '../LoginPage/LoginPage.css'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { URL } from '../../config';
const SignupPage = () => {
    const navigate = useNavigate();
    const storedData = JSON.parse(localStorage.getItem("user_data"));
    console.log(storedData)
    // State for role, email, and password
    const [role, setRole] = useState('');
    const [FirstName, setFirstName] = useState(storedData.first_name);
    const [LastName, setLastName] = useState(storedData.last_name);
    const [email, setEmail] = useState(storedData.email);
    const [password, setPassword] = useState('');
    const [mobile_id,setmobile_id]= useState('0')
    const [error, setError] = useState('');

    
    const handleRoleChange = (event) => {
        setRole(event.target.value);
    };
    const handlLastName = (e) => {
      setLastName(e.target.value)
   }
    const handleFirstName = (e) => {
       setFirstName(e.target.value)
    }
    const handleEmail = (e) => {
        setEmail(e.target.value);
    };

    const handlePassword = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = Cookies.get('authToken'); // test token 
        try {
            const response = await axios.post(`${URL}/update-profile`, {
              first_name: FirstName,
              last_name : LastName,
              email: email,
              mobile_id : mobile_id
              
            },
            {
                headers: {
                    token: `${token}`,
                  },

            }
         
        
        );
           const updatedUserData = {
             first_name: FirstName,
             last_name: LastName,
             email: email,
             mobile_id: mobile_id
           };
            localStorage.setItem("user_data", JSON.stringify(updatedUserData));
            // Handle successful login
             console.log('update successful:', response.data);
             
              // Save token in a cookie
             // const  token  = response.data.user.token;
             if (response.status === 200 || 201) { 
                console.log("update ok")
                setError("Data Updating Succesfully")
                //Cookies.set('authToken', Token, { expires: 7 }); // Cookie will expire in 7 days
                navigate('/a');
             }
            else if (response.status === 422 || 401){
                  setError("Wrong data sent");
            } 

        } catch (err) {
            console.error('update failed:', err);
            setError('update failed. Please check your credentials and try again.');
        }
    };

    return (
        <div className='SignUP_page_container'>
               <div className='UserPage_Header'>
                <Link to='/a' className='link'><h1>Lndex</h1></Link>
              
             </div>
            {role && (
                <div className="alert slide-in">
                    Selected Role: {role}
                </div>
            )}
            <div className='Registrition_Body'>
                {/* Start signup body */}
                <div className='Signup_body'>
                    <div className='Signup_page_Image'>
                        <img src={SignupImage} alt='SignupImage' />
                    </div>
                    <div className='Signup_body_Form'>
                        <div className='Signup_body_Form_Sec1'>
                            <img src={Logo} alt='Logo' />
                        </div>
                        <div className='Signup_body_Form_Sec2'>
                        Update Profile
                        </div>
                        <div className='Signup_body_Form_Sec3'>
                            <hr />
                        </div>
                        {/* Start input Field */}
                        <div className='Signup_body_input_container'>
                            {/* Email */}
                            <div className='Signup_body_Form_FirstAndLast'>
                              <div>
                                <div>First Name</div>
                                <div className='Signup_body_input'>
                                    <input
                                        className='Sign_input'
                                        type="text"
                                        placeholder={storedData.first_name}
                                        value={FirstName}
                                        onChange={handleFirstName}
                                    />
                                </div>
                                </div>
                                 {/* Last Name */}
                                <div>
                                 <div> Last Name</div>
                                <div className='Signup_body_input'>
                                    <input
                                        className='Sign_input'
                                        type="text"
                                        placeholder={storedData.last_name}
                                        value={LastName}
                                        onChange={handlLastName}
                                    />
                                </div>
                                </div> 
                            </div>
                              {/* Email */}
                              <div className='Signup_body_Form_Sec4'>
                                <div>Email</div>
                                <div className='Signup_body_input'>
                                    <input
                                        className='Sign_input'
                                        type="text"
                                        placeholder={storedData.email}
                                        value={email}
                                        onChange={handleEmail}
                                    />
                                </div>
                            </div>
                            {/* Password */}
                            <div className='Signup_body_Form_Sec4'>
                                <div>Mobile</div>
                                <div className='Signup_body_input'>
                                    <input
                                        className='Sign_input'
                                        type="text"
                                        placeholder='0122553268'
                                        value={password}
                                        onChange={handlePassword}
                                    />
                                </div>
                            </div>
                            {/* Role Selection */}
                            <div className='Login_page_DropDown'>
                                <label className='ROle' htmlFor="roleSelect">Select Role:</label>
                                <select id="roleSelect" value={role} onChange={handleRoleChange}>
                                    <option value="" disabled>Select your role</option>
                                    <option value="Instructor">Instructor</option>
                                    <option value="Student">Student</option>
                                </select>
                            </div>
                        </div>
                        {/* End input Field Container */}

                        <div className='Error_message' style={{display:'flex',justifyContent:'center',width:'90%',paddingRight:'50px'}}><span style={{color:'green',fontSize:'20px'}}>{error}</span></div>
                        <div className='Registration_body_Form_Sec5'>
                            <button className='Signup_body_Form_Sec5_button' onClick={handleSubmit}>
                                 Update
                            </button>
                            
                            <div><span> </span></div>
                           
                        </div>
                        <div className='Signup_body_Form_Sec3'>
                            <hr />
                        </div>
                        <div className='Signup_body_Form_Sec6_footer'>
                            by clicking on sign up, you agree to Lndex <span>Termes and conditions </span> & <span>Privacy policy</span> and Agree to receive news and promotions
                        </div>
                    </div> {/* End Form */}
                </div>
            </div>
        </div>
    );
};

export default SignupPage;