
import React , { useState , useEffect } from 'react'
import Footer from '../../component/Footer/Footer';
import FooterAR from '../../component/FooterArabic/FooterAr';
import { useLanguage } from '../../context/LanguageContext'
import { toHaveFormValues } from '@testing-library/jest-dom/matchers';
import ChangePassword from './ChangePassword';
import MobileNavbar from '../../component/mobileNavbar/MobileNavbar';
const IndexChangePassword = () => {
  
     // this from context api to manage language 
     const { language, toggleLanguage } = useLanguage();
     console.log('search page',language)
// const [language , toggleLanguage ]= useLanguage() ; .
// IsMobile and Responsive
const [isMobile, setIsMobile] = useState(false);
useEffect(() => {
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1200);
  };
  // Initial check
  handleResize();
  // Event listener for window resize
  window.addEventListener('resize', handleResize);
  // Clean-up
  return () => window.removeEventListener('resize', handleResize);
}, []);

  return (
    <div>
    
    <div style={{marginTop:'50px'}}></div>
   {isMobile && (
       <MobileNavbar /> 
   )}
  
    {/* Use showSignup and setShowSignup as needed */}
   {language === 'en' ? <ChangePassword />  : '<LoginPage />'} 
      {language === 'en' ?  <Footer/> : <FooterAR /> } 
    </div>

  )
}
export default IndexChangePassword