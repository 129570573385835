import React, { useState }from 'react'
import './userNavbar.css'
import SearchIcon from '../../assets/images/search_icon.png'
import Arrowup from '../../assets/images/Arrow_up.png'
import Notfication from '../../assets/images/Notfication.svg'
import ProfileIcon from '../../assets/images/singleUserIcon.svg'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom';
const UserNavbar = () => {
  const [searchText, setSearchText] = useState('');
  const storedData = JSON.parse(localStorage.getItem("user_data"));
  const ProfileSlug = storedData ? `${storedData.first_name}${storedData.last_name}` : '';
  const { slug } = useParams();  
  console.log(slug);
   const navigate = useNavigate() ;
  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  //  console.log(searchText)
  }; 
  const handleSearch = () => {
      // Add your search functionality here
      console.log("Searching for:", searchText);
    };
  const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        handleSearch();
      }
    };
    const handleIconClick = () => {
      handleSearch();
      handleKeyDown({ key: 'Enter' }); // Simulate Enter key press
    };
    
     const profileClick = ()=> {
      navigate(`/${ProfileSlug}`);
     } 
  
  return (
    <div className='usernavbar_Container'>
         {/* About leftSide  [search and button category] */}
       <div className='usernavbar_Leftside'>
       <div className="search-container">
                  <input 
                  className="User_Search" 
                  type="text" 
                  placeholder="Search..."
                  value={searchText}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  onClick={handleIconClick}
                  />
                  <img src={SearchIcon} alt="search icon" className="search-icon" onClick={handleIconClick}   />
             </div>
             <div><button className='UnauthAppbar_Second_app_button'>Categories <img src={Arrowup} alt='arrowicon'></img></button></div>
       </div>
         {/* About right-side   [Notification and user profile ] */}
         <div className='usernavbar_Rightside'>
             <img src={Notfication} alt='notfication_icon'/>
             <img src={ProfileIcon} alt="profile_icon" onClick={profileClick}/> 
         </div>

    </div>
  )
}

export default UserNavbar