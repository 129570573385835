import React from 'react'
import './SearchPage.css'
import { Link } from 'react-router-dom'
import ArrowLeftGuide from '../../assets/images/ArrowLeft.svg'
import Course from '../../component/Course/Course'
const SearchPage = () => {
  return (
    <div>
        <div className='SearchPage_Container'>
            {/* Section One #1  */}
            <div className='SearchPage_Sec1'>
            <Link to='/' className='link'>  <div>home</div> </Link>
               <div><img src={ArrowLeftGuide} alt='arrow'/></div>
               <div>Search result</div>
            </div>
             {/* Section #2  */}
             <div className='SearchPage_Sec2'>
                 <div className='SearchPage_Sec2_Part1'>
                       <span>9</span> <div>results for your search “Design”</div>
                 </div>
                 <div className='SearchPage_Sec2_Part2'>
                        {/* Section 3 */}
           
                 </div>
                 <div className='SearchPage_Sec2'>
                  <Course />
                  <Course />
                  <Course />
                  <Course />
                  <Course />
                  <Course />
                  <Course />
                  <Course />
                  <Course />
               </div>
             </div>
           
        </div>    {/* End Container  */}
    </div>
  )
}

export default SearchPage
