import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import Alert from 'react-bootstrap/Alert';

function AutoLayoutExample() {
  return (
    <Container>
      <Row>
        <Col xs={12} md={6}>1 of 2</Col>
        <Col xs={12} md={6}>2 of 2</Col>
      </Row>
      <Row>
        <Col xs={12} md={4}> 
          <Alert variant='info'>
            Wrong Password
          </Alert>
        </Col>
        <Col xs={12} md={4}> 
          <Alert variant='danger'>
            Wrong Password
          </Alert>
        </Col>
        <Col xs={12} md={4}>
          <Alert variant='danger'>
            Wrong Password
          </Alert>
        </Col>
      </Row>
    </Container>
  );
}

export default AutoLayoutExample;