import React, { useState } from 'react';
import SignupImage from '../../assets/images/signup.png';
import ExitIcon from '../../assets/images/Exit.png';
import Logo from '../../assets/images/lndexlogo_svg.svg';
import './Login.css';
//import Cookie from 'cookie-universal';
import axios from 'axios';

const Login = ({ setShowLogin }) => {
    //const cookies = Cookie();
    // exit sign form 
    const ExitLogin = () => {
        setShowLogin(false);
        };
    // Email
    const [email, setEmail] = useState('');
    const handleEmail = (e) => {
        setEmail(e.target.value);
    };
    // Password
    const [password, setPassword] = useState('');
    const handlePassword = (e) => {
        setPassword(e.target.value);
    };

    // Handle form submission
    /*
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      const loginData = {
          username: email, // Assuming email is used as username
          password: password ,
      };
  
      try {
          const response = await axios.post('https://qod.pythonanywhere.com/api/auth/jwt/create/', loginData, {
          });
          const token = response.data.access;
          cookies.set('authToken', token);
          console.log('Token:', token);
      } catch (error) {
          if (error.response) {
              // The request was made and the server responded with a status code that falls out of the range of 2xx
              console.error('Response error:', error.response.data);
          } else if (error.request) {
              // The request was made but no response was received
              console.error('Request error:', error.request);
          } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error', error.message);
          }
          console.error('Error config:', error.config);
      }
  };
  */
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('http://127.0.0.1:8000/api/login', {
        email: email,
        password: password
      });

      console.log('Login successful:', response.data);
      // Handle the JWT token or other data as needed

      // Clear the form fields
    
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

    return (
        <div className='Signup'>
            <div className='Signup_header'>
                <div>Student</div>
                <div>Instructor</div>
            </div>
            {/* Start signup body  */}
            <div className='Signup_body'>
                <div><img src={SignupImage} alt='SignupImage' /></div>
                <div className='Signup_body_Form'>
                    <div className='Signup_body_Form_Sec1'>
                        <img src={ExitIcon} alt='ExitIcon' onClick={ExitLogin} />
                        <img src={Logo} alt='Logo' />
                    </div>
                    <div className='Signup_body_Form_Sec2'>
                        Log In
                    </div>
                    <div className='Signup_body_Form_Sec3'>
                        <hr />
                    </div>
                    {/* Start input Field  */}
                    <div className='Signup_body_input_container'>
                        {/* Email */}
                        <div className='Signup_body_Form_Sec4'>
                            <div>Email</div>
                            <div className='Signup_body_input'>
                                <input
                                    className='Sign_input'
                                    type="text"
                                    placeholder='ayamustafa@gmail.com'
                                    value={email}
                                    onChange={handleEmail}
                                />
                            </div>
                        </div>
                        {/* Password */}
                        <div className='Signup_body_Form_Sec4'>
                            <div>Password</div>
                            <div className='Signup_body_input'>
                                <input
                                    className='Sign_input'
                                    type="password"
                                    placeholder='Password'
                                    value={password}
                                    onChange={handlePassword}
                                />
                            </div>
                        </div>
                    </div>
                    {/* End input Field Container */}
                    <div className='Signup_body_Form_Sec5'>
                        <button className='Signup_body_Form_Sec5_button' onClick={handleSubmit}>Log in</button>
                        <div>Don’t have an account?<span> Sign Up</span></div>
                    </div>
                    <div className='Signup_body_Form_Sec3'>
                        <hr />
                    </div>
                    <div className='Signup_body_Form_Sec6_footer'>
                        by clicking on sign up, you agree to Lndex <span>Termes and conditions </span>& <span>Privacy policy</span> and Agree to receive news and promotions
                    </div>
                </div> {/* End Form  */}
            </div>
        </div>
    );
};
export default Login;