import React from 'react'
import Map from './Map'
import IconLocation from '../../assets/images/LocationMap.svg'
const CourseLocation = () => {
  return (
    <div>
        {/* Course Location Container */}
        <div className='CourseLocation_Container'>
              <div className='CourseLocation_Sec1'>
                 Course Location
              </div>
              {/* Section 2 about title  */}
              <div className='CourseLocation_Sec2'>
              113 Al Kasr Al Aini, Ad Dawawin, Abdeen, Cairo Governorate 11511
              </div>
               {/* Section 3 Map */}
              <div className='CourseLocation_Sec3_map'>
                    <Map />
                        {/* Start Section 4 */}
              <div className='CourseLocation_Sec4'>
                  <img src={IconLocation} alt='IconLocation'></img>
                  <div>View on google map</div>
              </div>
              </div>
          
        </div>
    </div>
  )
}

export default CourseLocation 