import React ,{useState} from 'react'
import ArrowUp from '../../assets/images/Arrowup.svg'
import lineContent from '../../assets/images/lineContent.svg'
const Content = () => {
    
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
      setIsOpen(!isOpen);
    };
    // open second 
    const [secondopen , setsecondOpen] = useState(false)
    // function be true or false 
    const toggleSecondPart = () => {
      setsecondOpen(!secondopen)   
    }
     // open Third 
     const [thirdopen , setthirdOpen] = useState(false)
     // function be true or false 
     const toggleThirdPart = () => {
        setthirdOpen(!thirdopen)   
     }
    

  return (
    <div className='CourseDetails_Content_Container'>
          <div className='CourseDetails_Content_Sec1'>
      <div onClick={toggleAccordion} className='CourseDetails_Content_question'>
        <h2>Introduction To Marketing</h2>
        <div style={{ marginLeft: '10px' }}>
          {isOpen ?   <img src={ArrowUp} alt='ArrowDown' style={{transform:'rotate(180deg)', transition: 'transform 0.3s',}}></img> : <img src={ArrowUp} alt='arrowup'></img>}
        </div>
      </div>
      <div><img src={lineContent} alt='lineContent'></img></div>
      {isOpen && (
        <ul className='CourseDetails_Content_Answer'>
          <li> What Is Marketing</li>
          <li> History Of Marketing</li>
          <li> Marketing Play</li>
        </ul>
      )}
   
          </div>
           {/* Sec 2  */}
           <div className='CourseDetails_Content_Sec1'>
                  <div onClick={toggleSecondPart} className='CourseDetails_Content_question'>
                     <h2>Deep dive into marketing</h2>
                     {secondopen ? <img src={ArrowUp} alt='ArrowDown' style={{transform:'rotate(180deg)', transition: 'transform 0.3s',}}></img> : <img src={ArrowUp} alt='arrowup'></img>}
                  </div>
                  <div>
                  <div><img src={lineContent} alt='lineContent'></img></div>
                     {secondopen && (
                            <ul className='CourseDetails_Content_Answer'>
                            <li>Explain the difference between marketing and sales.</li>
                            <li>Identify the four Ps of marketing and explain their importance.</li>
                            <li>Conduct market research using primary and secondary data</li>
                          </ul>
                     )}
                  </div>
           </div>
               {/* Sec 3  */}
            <div className='CourseDetails_Content_Sec1'>
            <div onClick={toggleThirdPart} className='CourseDetails_Content_question'>
                     <h2>Final project</h2>
                     {thirdopen ? <img src={ArrowUp} alt='ArrowDown' style={{transform:'rotate(180deg)', transition: 'transform 0.3s',}}></img> : <img src={ArrowUp} alt='arrowup'></img>}
                  </div>
                  <div>
                  <div><img src={lineContent} alt='lineContent'></img></div>
                     {thirdopen && (
                            <ul className='CourseDetails_Content_Answer'>
                            <li>Explain the difference between marketing and sales.</li>
                            <li>Identify the four Ps of marketing and explain their importance.</li>
                            <li>Conduct market research using primary and secondary data</li>
                          </ul>
                     )}
                  </div>
            </div>
    </div>
  )
}

export default Content