import React , { useState } from 'react'
import UserProfile from './UserProfile';
import UnauthAppbar from '../../component/AppbarUnAuth/UnauthAppbar'
import Signup from '../../component/Signup/Signup';
import Login from '../../component/Login/Login';
import Footer from '../../component/Footer/Footer';
import UserNavbar from '../../component/UserNavbar/UserNavbar';
const IndexUser = () => {
    const [showSignup, setShowSignup] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
  return (
    <div>
      {/* 
    <UnauthAppbar showSignup={showSignup} setShowSignup={setShowSignup}
    showLogin={showLogin}
    setShowLogin={setShowLogin}
    />
    */}
    {/* Use showSignup and setShowSignup as needed */}
  
       <UserProfile />
       <Footer/>
    </div>
  )
}

export default IndexUser