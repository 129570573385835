import React , { useState } from 'react'
import UnauthAppbar from '../../component/AppbarUnAuth/UnauthAppbar'
import UnauthappbarAR from '../../component/AppbarUnAuth/UnauthAppbarAR'
import Signup from '../../component/Signup/Signup';
import Login from '../../component/Login/Login';
import Footer from '../../component/Footer/Footer';
import SearchPage from './SearchPage';
import FooterAR from '../../component/FooterArabic/FooterAr';
import { useLanguage } from '../../context/LanguageContext'
const IndexSearch = () => {
    const [showSignup, setShowSignup] = useState(false);
    const [showLogin, setShowLogin] = useState(false);

     // this from context api to manage language 
     const { language, toggleLanguage } = useLanguage();
     console.log('search page',language)
// const [language , toggleLanguage ]= useLanguage() ; 
  return (
    <div>
      {language === 'en' ?  <UnauthAppbar showSignup={showSignup} setShowSignup={setShowSignup}
    showLogin={showLogin}
    setShowLogin={setShowLogin}
    /> 
          : 
          <UnauthappbarAR showSignup={showSignup} setShowSignup={setShowSignup}
    showLogin={showLogin}
    setShowLogin={setShowLogin}
    /> 
  }
   
    {/* Use showSignup and setShowSignup as needed */}
    {showSignup ? <div className='Signup_home'><Signup setShowSignup={setShowSignup}/></div> : ''} 
    {showLogin ?  <div className='Signup_home'><Login setShowLogin={setShowLogin}/> </div> : ''}
    <SearchPage />
      {language === 'en' ?  <Footer/> : <FooterAR /> } 
    </div>
  )
}

export default IndexSearch