import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const useAuth = () => {
  const navigate = useNavigate();
  const token = Cookies.get('authToken');//authToken

  useEffect(() => {
    if (!token) {
      navigate('/login'); // Redirect to login if token is not present
    }
  }, [token, navigate]);

  return !!token; // Return true if token exists, false otherwise
};

export default useAuth;
