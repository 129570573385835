import React from 'react'
import Article from '../Article/Article'
const NewArticle = () => {
  return (
    <div className='Blog_Sec5'>
      <Article />
      <Article />
    </div>
  )
}

export default NewArticle
