import React from 'react'
import LndexLogo from '../../assets/images/lndexlogo_svg.svg'
import Line from '../../assets/images/Line.svg'
import FacebookIcon from '../../assets/images/facebookicon.svg'
import YoutubeIcon from '../../assets/images/youtubeicon.svg'
import ArrowUp from '../../assets/images/Scrollup.png'
import InstgramIcon from '../../assets/images/instgramicon.svg'
import './FooterAr.css'
const FooterAR = () => {
  const scrollToTop = () => {
    console.log('Scroll to top triggered');
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <div>
          {/*  Start Sec 8 === Footer Section   */}
          <div className='Home_Sec8_Footer_ar'>
          <div className='Home_Sec8_Footer_Sec1_ar' onClick={scrollToTop}>الرجوع للاعلي  <img src={ArrowUp} alt='ArrowUp' onClick={scrollToTop}/></div>
           <div className='Line'><img src={Line} alt='Line'/></div>
          <div className='Home_Sec8_Footer_Sec2_ar'>
               <img src={LndexLogo} alt='LndexLogo'/>
          </div>
          <div className='Home_Sec8_Footer_Sec3_ar'>
           <ul>
              <li>عن ليندكس</li>
              <li>اتصل بنا</li>
              <li>المساعده & الدعم</li>
              <li>سجل كمدرس</li>
              <li>شروط</li>
              <li>سياسة الخصوصية</li>
           </ul>
          </div>
          <div className='Home_Sec8_Footer_Sec4_ar'>
              <img src={FacebookIcon} alt='FacebookIcon'/>
              <img src={YoutubeIcon} alt='youtubeIcon'/>
              <img src={InstgramIcon} alt='insta'/>
          </div>
          <div className='Line'><img src={Line} alt='Line'/></div>
          <div className='Home_Sec8_Footer_Sec5_ar'>
          @Copyright Lndex All rights Reserved
          </div>
   </div>
    </div>
  )
}
export default FooterAR