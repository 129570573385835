
import React, { useState } from 'react';
import axios from 'axios';
import SignupImage from '../../assets/images/signup.png';
import Logo from '../../assets/images/lndexlogo_svg.svg';
import Cookies from 'js-cookie';
import '../LoginPage/LoginPage.css'
import { useNavigate } from 'react-router-dom';
import { URL } from '../../config';
const SignupPage = () => {
    const navigate = useNavigate();
    // State for role, email, and password
    const [role, setRole] = useState('');
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [mobile_id,setmobile_id]= useState('0')
    const [error, setError] = useState('');


    const handleRoleChange = (event) => {
        setRole(event.target.value);
    };
    const handlLastName = (e) => {
      setLastName(e.target.value)
   }
    const handleFirstName = (e) => {
       setFirstName(e.target.value)
    }
    const handleEmail = (e) => {
        setEmail(e.target.value);
    };

    const handlePassword = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${URL}/register`, {
              first_name: FirstName,
              last_name : LastName,
              email: email,
              password: password,
              mobile_id : mobile_id
            });

            // Handle successful login
             console.log('Signup successful:', response.data);
              // Save token in a cookie
             // const  token  = response.data.user.token;
             if (response.status === 200 || 201) {
                const Token = response.data.data.user.token ; 
                console.log("Test Token",Token)
                Cookies.set('authToken', Token, { expires: 7 }); // Cookie will expire in 7 days
                const fName = response.data.data.user.first_name;
                const lName = response.data.data.user.last_name;
                const email = response.data.data.user.email;
                const userData = {
                  first_name: fName,
                  last_name: lName,
                  email: email
              };
              
              localStorage.setItem("user_data", JSON.stringify(userData));
                navigate('/a');
             }
            else if (response.status === 422 || 401){
                  setError("Wrong Email Or Password");
            } 

        } catch (err) {
            console.error('Login failed:', err);
            setError('Login failed. Please check your credentials and try again.');
        }
    };

    return (
        <div className='SignUP_page_container'>
            {role && (
                <div className="alert slide-in">
                    Selected Role: {role}
                </div>
            )}
            <div className='Registrition_Body'>
                {/* Start signup body */}
                <div className='Signup_body'>
                    <div className='Signup_page_Image'>
                        <img src={SignupImage} alt='SignupImage' />
                    </div>
                    <div className='Signup_body_Form'>
                        <div className='Signup_body_Form_Sec1'>
                            <img src={Logo} alt='Logo' />
                        </div>
                        <div className='Signup_body_Form_Sec2'>
                            Sign Up 
                        </div>
                        <div className='Signup_body_Form_Sec3'>
                            <hr />
                        </div>
                        {/* Start input Field */}
                        <div className='Signup_body_input_container'>
                            {/* Email */}
                            <div className='Signup_body_Form_FirstAndLast'>
                              <div>
                                <div>First Name</div>
                                <div className='Signup_body_input'>
                                    <input
                                        className='Sign_input'
                                        type="text"
                                        placeholder='Aya'
                                        value={FirstName}
                                        onChange={handleFirstName}
                                    />
                                </div>
                                </div>
                                 {/* Last Name */}
                                <div>
                                 <div> Last Name</div>
                                <div className='Signup_body_input'>
                                    <input
                                        className='Sign_input'
                                        type="text"
                                        placeholder='aya mustafa'
                                        value={LastName}
                                        onChange={handlLastName}
                                    />
                                </div>
                                </div> 
                            </div>
                              {/* Email */}
                              <div className='Signup_body_Form_Sec4'>
                                <div>Email</div>
                                <div className='Signup_body_input'>
                                    <input
                                        className='Sign_input'
                                        type="text"
                                        placeholder='ayamustafa@gmail.com'
                                        value={email}
                                        onChange={handleEmail}
                                    />
                                </div>
                            </div>
                            {/* Password */}
                            <div className='Signup_body_Form_Sec4'>
                                <div>Password</div>
                                <div className='Signup_body_input'>
                                    <input
                                        className='Sign_input'
                                        type="password"
                                        placeholder='Password'
                                        value={password}
                                        onChange={handlePassword}
                                    />
                                </div>
                            </div>
                            {/* Role Selection */}
                            <div className='Login_page_DropDown'>
                                <label className='ROle' htmlFor="roleSelect">Select Role:</label>
                                <select id="roleSelect" value={role} onChange={handleRoleChange}>
                                    <option value="" disabled>Select your role</option>
                                    <option value="Instructor">Instructor</option>
                                    <option value="Student">Student</option>
                                </select>
                            </div>
                        </div>
                        {/* End input Field Container */}

                        <div className='Error_message'>{error}</div>
                        <div className='Registration_body_Form_Sec5'>
                            <button className='Signup_body_Form_Sec5_button' onClick={handleSubmit}>
                                 Sign Up
                            </button>
                            
                            <div>Already have an account?<span> Sign Up</span></div>
                           
                        </div>
                        <div className='Signup_body_Form_Sec3'>
                            <hr />
                        </div>
                        <div className='Signup_body_Form_Sec6_footer'>
                            by clicking on sign up, you agree to Lndex <span>Termes and conditions </span> & <span>Privacy policy</span> and Agree to receive news and promotions
                        </div>
                    </div> {/* End Form */}
                </div>
            </div>
        </div>
    );
};

export default SignupPage;