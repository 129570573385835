
import React , { useState , useEffect } from 'react'
import Footer from '../../component/Footer/Footer';
import FooterAR from '../../component/FooterArabic/FooterAr';
import { useLanguage } from '../../context/LanguageContext'
import Home from './UserHome';
import MobileNavbar from '../../component/mobileNavbar/MobileNavbar';
import Sidebar from '../../component/Sidebar'
import useAuth from '../../CustomHooks/useAuth';
const IndexUserHome = () => {
       useAuth()
     // this from context api to manage language 
     const { language, toggleLanguage } = useLanguage();
     console.log('search page',language)
     const isLogggedin = true
// const [language , toggleLanguage ]= useLanguage() ; .
// IsMobile and Responsive
const [isMobile, setIsMobile] = useState(false);
useEffect(() => {
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1200);
  };
  // Initial check
  handleResize();
  // Event listener for window resize
  window.addEventListener('resize', handleResize);
  // Clean-up
  return () => window.removeEventListener('resize', handleResize);
}, []);

  return (
    <div style={{display:'flex',gap:'264px'}}>
       <>
       {!isMobile && (<>
        {language === 'en' && isMobile === false ? 
           <Sidebar />
          : 
        <div> navbar </div>
     } 
       </>)
       }
       </>
       <>
   {isMobile && (
       <MobileNavbar /> 
   )}

      {language === 'en' ? <Home />  : <h1>hakim</h1>} 
      {/* {language === 'en' ?  <Footer/> : <FooterAR /> }  */} 
      </>
    </div>

  )
}

export default IndexUserHome
