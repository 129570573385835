import React, { useState } from 'react';
import axios from 'axios';
import SignupImage from '../../assets/images/signup.png';
import Logo from '../../assets/images/lndexlogo_svg.svg';
import './LoginPage.css';
import Cookies from 'js-cookie';
import { Link, useNavigate } from 'react-router-dom';
// redux-toolkit for manage APi
import { useDispatch , useSelector } from 'react-redux';
import { loginUser } from '../../store/featureAuth';
import { LineUtil } from 'leaflet';
import Spinner from 'react-bootstrap/Spinner';
const LoginPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, error, user } = useSelector((state) => state.auth);
    // State for role, email, and password
    const [role, setRole] = useState('');
    const [email, setEmail] = useState('ahmed@hakim.com');
    const [password, setPassword] = useState('123456');
    const [err, setError] = useState('');
    //const [credentials, setCredentials] = useState({ email: '', password: '' });
    // function Login
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(loginUser({ email, password }))
          .unwrap()
          .then(() => {
            navigate('/a');
          })
          .catch((err) => {
            console.error(err);
          });
      };

    const handleRoleChange = (event) => {
        setRole(event.target.value);
    };
       
    const handleEmail = (e) => {
        setEmail(e.target.value);
    };

    const handlePassword = (e) => {
        setPassword(e.target.value);
    };
    
     /*
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://127.0.0.1:8000/api/login', {
                email,
                password
            });

            // Handle successful login
             console.log('Login successful:', response.data);
              // Save token in a cookie
             // const  token  = response.data.user.token;
             if (response.status === 200 || 201) {
                const Token = response.data.data.user.token ; 
                console.log("Test Token",Token)
                Cookies.set('authToken', Token, { expires: 7 }); // Cookie will expire in 7 days
                navigate('/');
             }
            else if (response.status === 422 || 401){
                  setError("Wrong Email Or Password");
            } 

        } catch (err) {
            console.error('Login failed:', err);
            setError('Login failed. Please check your credentials and try again.');
        }
    };
      */
    return (
        <div className='Login_page_container'>
            {role && (
                <div className="alert slide-in">
                    Selected Role: {role}
                </div>
            )}
            <div className='Signup'>
                {/* Start signup body */}
                <div className='Signup_body'>
                    <div className='Login_page_hidden'>
                        <img src={SignupImage} alt='SignupImage' />
                    </div>
                    <div className='Signup_body_Form'>
                        <div className='Signup_body_Form_Sec1'>
                            <img src={Logo} alt='Logo' />
                        </div>
                        <div className='Signup_body_Form_Sec2'>
                            Log In
                        </div>
                        <div className='Signup_body_Form_Sec3'>
                            <hr />
                        </div>
                        {/* Start input Field */}
                        <div className='Signup_body_input_container'>
                            {/* Email */}
                            <div className='Signup_body_Form_Sec4'>
                                <div>Email</div>
                                <div className='Signup_body_input'>
                                    <input
                                        className='Sign_input'
                                        type="text"
                                        placeholder='ayamustafa@gmail.com'
                                        value={email}
                                        onChange={handleEmail}
                                    />
                                </div>
                            </div>
                            {/* Password */}
                            <div className='Signup_body_Form_Sec4'>
                                <div>Password</div>
                                <div className='Signup_body_input'>
                                    <input
                                        className='Sign_input'
                                        type="password"
                                        placeholder='Password'
                                        value={password}
                                        onChange={handlePassword}
                                    />
                                </div>
                            </div>
                            {/* Role Selection */}
                            <div className='Login_page_DropDown'>
                                <label className='ROle' htmlFor="roleSelect">Select Role:</label>
                                <select id="roleSelect" value={role} onChange={handleRoleChange}>
                                    <option value="" disabled>Select your role</option>
                                    <option value="Instructor">Instructor</option>
                                    <option value="Student">Student</option>
                                </select>
                            </div>
                        </div>
                        {/* End input Field Container */}

                        <div className='Error_message'>     {error && (
        <div>
          <p></p>
          <p>Error Message: {error.msg}</p>
          {/* Optionally render more details if needed */}
          {error.data && <pre>{JSON.stringify(error.data.data, null, 2)}</pre>}
        </div>
      )}
   </div>
                        <div className='Signup_body_Form_Sec5'>

                      {loading ?  <Spinner animation="border" variant="primary" /> : <button className='Signup_body_Form_Sec5_button' onClick={handleSubmit}>
                               Login
                            </button>}        
                           
                            <div style={{display:'flex',flexDirection:"column",alignItems:'flex-end',gap:'10px'}}>
                           <Link to='/forget-password'>  <div style={{cursor:'pointer'}}>? Forget Password </div> </Link>
                            <div>Don’t have an account?<span> Sign Up</span></div>
                            </div>   
                        </div>
                        <div className='Signup_body_Form_Sec3'>
                        
                            <hr />
                        </div>
                        <div className='Signup_body_Form_Sec6_footer'>
                            by clicking on sign up, you agree to Lndex <span>Termes and conditions </span> & <span>Privacy policy</span> and Agree to receive news and promotions
                        </div>
                    </div> {/* End Form */}
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
