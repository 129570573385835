import React, { useState , useEffect } from 'react';
import './mobileNavbar.css';
import Logo from '../../assets/images/lndexlogo_svg.svg';
import mobilemenu from '../../assets/images/OpenMenu.svg'
import mobilemenuClose from '../../assets/images/CloseMenu.svg'
import BlogIcon from '../../assets/images/blogSvg.svg'
// import Line from '../../assets/images/Line.svg'
import ArabicIcon from '../../assets/images/worldLanguage.svg'
import ShoppingCart from '../../assets/images/shopping-cart.png'
import SavedCourse from '../../assets/images/Course/Saved.svg'
import SigniconMobile from '../../assets/images/Sign_mobile_menu.svg'
import LoginiconMobile from '../../assets/images/Login_mobile_menu.svg'
import { Link } from 'react-router-dom';
const MobileNavbar = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };
   // now prevent scroll
   useEffect(() => {
    if (isSidebarVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Cleanup function to reset the overflow style when the component unmounts or sidebar visibility changes
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isSidebarVisible]);

  return (
    <div className='mobileNavbar_Container'>
      <div className='mobileNavbar_Sec1'>
        <img className='mobileNavbar_Logo' src={Logo} alt='Logo' />
        <span onClick={toggleSidebar} className='menuIcon'>{isSidebarVisible === true ? <img src={mobilemenu} alt='mobilemenu' /> : <img src={mobilemenuClose} alt='mobilemenuClose' />}</span>
      </div>
      {isSidebarVisible && (
    <div className={`mobileNavbar_sidebar ${isSidebarVisible ? 'visible' : ''}`}>
    {/* Your sidebar content here */}
    <ul className='mobileNavbar_sidebar_content'>
    <li className='mobileNavbar_sidebar_content_Auth'>  <Link to='/Signup' className='link'><div style={{display:'flex',gap:'8px'}}> <img style={{width:'25px'}} src={SigniconMobile} alt='SigniconMobile'/> <div>Sign up</div> </div></Link> | <Link to='/Login' className='link'></Link>  <div style={{display:'flex',gap:'8px'}}><img style={{width:'25px'}} src={LoginiconMobile} alt='LoginiconMobile'/><div>Login</div></div></li>
        <li className='mobileNavbar_sidebar_content_Item'><img src={BlogIcon} alt='BlogIcon'/> <div>Blog</div></li>
        {/* <li><img className='mobileNavbar_sidebar_content_Line' src={Line} alt='Line' /></li>  */}  
        <li  className='mobileNavbar_sidebar_content_Item'> <div><img src={ArabicIcon} alt='ArabicIcon'/></div><div >English</div></li>
        <li className='mobileNavbar_sidebar_content_Item'><img src={ShoppingCart} alt='ShoppingCart'/><div>Cart</div></li>
        <li className='mobileNavbar_sidebar_content_Item'><img src={SavedCourse} alt='SavedCourse'/><div>Saved Courses</div></li>
    </ul>
    </div>
      )}
    </div>
  );
};

export default MobileNavbar;


