import React from 'react'
import './Instructor.css'
import InstructorIcon from '../../assets/images//instructors/instructor1.png'
import Start from '../../assets/images/Course/Star.png'
const Instructor = () => {
  return (
    <div>
       <div className='Instructor_Container'>
          <div className='Instructor_Sec1'>
            <img src={InstructorIcon} alt='InstructorIcon'></img>
          </div>
          {/*  Second Section  */}
          <div className='Instructor_Sec2'>
          Dr.omran
          </div>
          <div className='Instructor_Sec3'>
          Lifecoaching Specialist
          </div>
          <div className='Instructor_Sec4'>
            <img src={Start} alt='Start'></img> <div>95% (234)</div>
          </div>
          <div className='Instructor_Sec5'>
               <button>Follow</button>
          </div>
       </div>
    </div>
  )
}

export default Instructor
